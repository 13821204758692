import { useEffect, useState } from "react";

export function useCountdown(mins) {
  const [secs, decrement] = useState(mins * 60);

  useEffect(() => {
    if (secs > 0) {
      const tick = setInterval(() => {
        decrement(secs - 1);
      }, 1000);
      return () => clearInterval(tick);
    }
  }, [secs, mins]);

  const setTimer = (minutes) => {
    decrement(minutes * 60);
  };

  const isOver = () => secs === 0;

  const min = parseInt(secs / 60, 10);
  const sec = parseInt(secs % 60, 10);
  const minutes = min < 10 ? "0" + min : min;
  const seconds = sec < 10 ? "0" + sec : sec;

  return [minutes, seconds, setTimer, isOver];
}
