import { React } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import theme from "./theme";

function App() {
  return (
    <ChakraProvider resetCSS theme={theme}>
      <RouterProvider router={router} />
    </ChakraProvider>
  );
}

export default App;
