import { createBrowserRouter, redirect, defer } from "react-router-dom";
import {
  getCurrentUser,
  updateHubspotContact,
  signInWithToken,
  onAuthStateChanged2,
} from "./firebase";
import LoginScreen from "./screens/LoginScreen";
import AdmissionProcessScreen from "./screens/AdmissionProcessScreen";

const authLoader = async ({ request }) => {
  let currentUser = null;
  const token = new URL(request.url).searchParams.get("token");

  await new Promise((resolve, reject) => {
    onAuthStateChanged2(async (user) => {
      if (user && !token) {
        currentUser = user;
      } else if (token) {
        currentUser = await signInWithToken(token);
      }

      try {
        const {
          data: { progress },
        } = await getCurrentUser();

        if (progress === 0) {
          updateHubspotContact({ progress: "Comenzado" });
          localStorage.clear();
        }

        resolve();
      } catch (error) {
        reject(Response.redirect("login"));
      }
    });
  });

  if (!currentUser) {
    return redirect("/login");
  }

  return defer(currentUser);
};

export default createBrowserRouter(
  [
    {
      path: "/:bootcampId",
      element: <AdmissionProcessScreen />,
      loader: authLoader,
    },
    {
      path: "/login",
      element: <LoginScreen />,
    },
  ],
  { basename: "/admission" }
);
