import { useEffect } from "react";
import {
  Alert,
  AlertIcon,
  Box,
  CloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { useCurrentUser } from "../../../hooks/useCurrentUser";

export function InterviewStep({ meetingUrl, description }) {
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const currentUser = useCurrentUser();

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [currentUser]);

  return (
    <Box my={8}>
      {isOpen && (
        <Alert status="info" mb={4} colorScheme="teal" variant="left-accent">
          <AlertIcon />
          {description}
          <CloseButton
            alignSelf="flex-start"
            position="relative"
            right={-1}
            top={-1}
            onClick={onClose}
          />
        </Alert>
      )}

      {Object.keys(currentUser).length > 0 &&
        currentUser.email !== undefined && (
          <div
            className="meetings-iframe-container"
            data-src={`${meetingUrl}?embed=true&firstName=${currentUser.firstname}&lastName=${currentUser.lastname}&email=${currentUser.email}`}
          ></div>
        )}
    </Box>
  );
}
