import { useEffect } from "react";
import {
  Box,
  Heading,
  Hide,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Text,
  useSteps,
} from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import { updateCurrentUser } from "../../firebase";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { WelcomeStep } from "./Steps/WelcomeStep";
import { ReasoningStep } from "./Steps/ReasoningStep";
import { InterviewStep } from "./Steps/InterviewStep";

function StepManager({ type = "welcome", ...props }) {
  const steps = {
    welcome: <WelcomeStep {...props} />,
    reasoning: <ReasoningStep {...props} />,
    interview: <InterviewStep {...props} />,
  };

  return steps[type];
}

export function Steps({ steps = [] }) {
  const [searchParams] = useSearchParams();
  const currentUser = useCurrentUser();
  const { activeStep, goToNext, setActiveStep } = useSteps({
    index: currentUser.progress,
    count: steps.length,
  });

  useEffect(() => {
    if (currentUser.progress) {
      setActiveStep(currentUser.progress);
    }

    if (searchParams.get("redirect") === "true") {
      setActiveStep(3);
      updateCurrentUser({ progress: 3 });
    }
  }, [currentUser]);

  return (
    <>
      <Stepper index={activeStep}>
        {steps.map((step, index) => (
          <Step key={index}>
            <StepIndicator background="white">
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>

            <Hide below="sm">
              <Box flexShrink="0">
                <StepTitle>{step.name}</StepTitle>
                <StepDescription>{step.description}</StepDescription>
              </Box>
            </Hide>
            <StepSeparator />
          </Step>
        ))}
      </Stepper>
      {steps.map((step, index) => {
        if (activeStep === index)
          return (
            <StepManager
              key={index}
              type={step.type}
              {...step.props}
              goToNext={goToNext}
            />
          );

        return null;
      })}

      {activeStep === steps.length && (
        <Box
          px={{ base: 6, sm: 12 }}
          py={{ base: 8, sm: 16 }}
          w="100%"
          my={8}
          bg="white"
          rounded="md"
          shadow="md"
        >
          <Heading size="md" mb={8}>
            ¡Gracias por participar!
          </Heading>

          <div>
            <Text>
              ¡Muchas gracias! Se han enviado tus resultados y ya tienes
              agendada tu cita. Por favor revisa tu correo, ahí tendrás toda la
              información sobre la cita y siguientes pasos.
            </Text>
            <Text>Nos vemos pronto :)</Text>
          </div>
        </Box>
      )}
    </>
  );
}
