import { useEffect, useState } from "react";
import config from "../config";

export function useAdmissionProcess(bootcampId) {
  const [admissionProcess, setAdmissionProcess] = useState({});

  useEffect(() => {
    if (config[bootcampId]) {
      setAdmissionProcess(config[bootcampId]);
    }
  }, [bootcampId]);

  return admissionProcess;
}
