import { useForm } from "react-hook-form";
import {
  Input,
  AspectRatio,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  Heading,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react";
import { updateCurrentUser, updateHubspotContact } from "../../../firebase";
import { useEffect } from "react";

export function WelcomeStep({ title, introduction, videoUrl, form, goToNext }) {
  const {
    handleSubmit,
    register,
    unregister,
    setError,
    watch,
    formState: { errors, isSubmitting },
  } = useForm();

  async function onSubmit(values) {
    const properties = form.fields.reduce((acc, field) => {
      acc[field.hubspotId] = values[field.name];
      return acc;
    }, {});

    const firebaseValues = form.fields.reduce((acc, field) => {
      acc[field.name] = values[field.name];
      return acc;
    }, {});

    if (firebaseValues["validTimeZone"] === "No") {
      setError("validTimeZone", {
        type: "custom",
        message:
          "Ten en cuenta que debes poder asistir a las clases en directo en el horario indicado en nuestra página web",
      });

      return;
    }

    firebaseValues["whyAdalabOther"] = values["whyAdalabOther"];

    const response = await updateHubspotContact({
      ...properties,
      progress: "HTML",
      other_reason_to_choose_adalab: firebaseValues["whyAdalabOther"],
    });

    await updateCurrentUser({ ...firebaseValues, progress: 1 });

    goToNext();
    window.scrollTo(0, 0);

    return response;
  }

  const whyAdalabOptions = watch("whyAdalab");

  useEffect(() => {
    if (whyAdalabOptions === "Otro") {
      register("whyAdalabOther", {
        required: "Este campo es requerido",
      });
    } else {
      unregister("whyAdalabOther");
    }
  }, [register, unregister, whyAdalabOptions]);

  return (
    <Box
      px={{ base: 6, sm: 12 }}
      py={{ base: 8, sm: 16 }}
      w="100%"
      my={8}
      bg="white"
      rounded="md"
      shadow="md"
    >
      <Heading size="md" mb={8}>
        {title}
      </Heading>

      <div>{introduction}</div>

      {videoUrl && (
        <AspectRatio maxW="100%" ratio={16 / 9} my={8}>
          <iframe title="naruto" src={videoUrl} allowFullScreen />
        </AspectRatio>
      )}

      {form && (
        <>
          <Text>{form.introduction}</Text>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack mt={8} spacing={4}>
              {form.fields.map((field, index) => {
                if (field?.options) {
                  return (
                    <FormControl key={index} isInvalid={errors[field.name]}>
                      <Select
                        id={field.name}
                        placeholder={field.label}
                        size="lg"
                        {...register(field.name, {
                          required: "Este campo es requerido",
                        })}
                      >
                        {field.options.map((option, index) => (
                          <option key={index} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Select>
                      <FormErrorMessage>
                        {errors[field.name] && errors[field.name].message}
                      </FormErrorMessage>
                    </FormControl>
                  );
                } else {
                  return (
                    <FormControl isInvalid={errors[field.name]} key={index}>
                      <Input
                        type={field.type}
                        id={field.name}
                        placeholder={field.label}
                        size="lg"
                        value={field.value}
                        {...register(field.name, {
                          required: "Este campo es requerido",
                        })}
                      />
                      <FormErrorMessage>
                        {errors[field.name] && errors[field.name].message}
                      </FormErrorMessage>
                    </FormControl>
                  );
                }
              })}
              {whyAdalabOptions === "Otro" ? (
                <FormControl isInvalid={errors["whyAdalabOther"]}>
                  <Input
                    type="text"
                    id="whyAdalabOther"
                    placeholder="Si has seleccionado 'otro', indica cuál"
                    size="lg"
                    {...register("whyAdalabOther", {
                      required: "Este campo es requerido",
                    })}
                  />
                  <FormErrorMessage>
                    {errors["whyAdalabOther"] &&
                      errors["whyAdalabOther"].message}
                  </FormErrorMessage>
                </FormControl>
              ) : null}
            </Stack>

            <HStack mt={8} justify="end">
              <Button
                size="lg"
                width={{ base: "100%", sm: "auto" }}
                isLoading={isSubmitting}
                type="submit"
              >
                Comenzar
              </Button>
            </HStack>
          </form>
        </>
      )}
    </Box>
  );
}
