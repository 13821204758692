import { Container, HStack, Image } from "@chakra-ui/react";
import logo from "../assets/logo.svg";

export function Screen({ children, withHeader = true }) {
  return (
    <>
      {withHeader && (
        <HStack h="60px" background="white" boxShadow="sm">
          <Image src={logo} boxSize="100px" ml={8} />
        </HStack>
      )}
      <Container maxW="5xl" py={8}>
        {children}
      </Container>
    </>
  );
}
