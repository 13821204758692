import { useEffect, useState } from "react";
import { getCurrentUser } from "../firebase";

export function useCurrentUser() {
  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    getCurrentUser().then((user) => {
      setCurrentUser(user.data);
    });
  }, []);

  return currentUser;
}
