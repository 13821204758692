import { initializeApp } from "firebase/app";
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";
import {
  getAuth,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  connectAuthEmulator,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDWx_t7t8wwOxr1HFc-d1HLz8LaTBLMpfA",
  authDomain: "adalab-apps-infrastructure.firebaseapp.com",
  projectId: "adalab-apps-infrastructure",
  storageBucket: "adalab-apps-infrastructure.appspot.com",
  messagingSenderId: "750915509206",
  appId: "1:750915509206:web:1fc980ab08ac8d75dca277",
  measurementId: "G-GRZZNR6C2F",
};

const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);
const auth = getAuth(app);

if (process.env.NODE_ENV === "development") {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFunctionsEmulator(functions, "localhost", 5001);
}

export const signInWithToken = async (token) => {
  try {
    const userCredential = await signInWithCustomToken(auth, token);
    return userCredential.user;
  } catch (error) {
    console.log(error.message);
  }
};

export const signIn = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    return userCredential.user;
  } catch (error) {
    console.log(error.message);
  }
};

export const onAuthStateChanged2 = (callback) => {
  onAuthStateChanged(auth, callback);
};

export const updateHubspotContact = httpsCallable(
  functions,
  "updateHubspotContact"
);

export const updateCurrentUser = httpsCallable(functions, "updateCurrentUser");
export const getCurrentUser = httpsCallable(functions, "getCurrentUser");
