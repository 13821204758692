import { useForm } from "react-hook-form";
import { useEffect } from "react";
import {
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Flex,
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Image,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
} from "@chakra-ui/react";
import { HiClock, HiOutlineInformationCircle } from "react-icons/hi";
import { updateCurrentUser, updateHubspotContact } from "../../../firebase";
import { useCountdown } from "../../../hooks/useCountdown";

export function ReasoningStep({
  title,
  introduction,
  test,
  countdownTimer,
  goToNext,
}) {
  const {
    handleSubmit,
    register,
    formState: { isSubmitting },
  } = useForm();

  let isCompleted = false;

  const [minutes, seconds, setTimer, isOver] = useCountdown(
    countdownTimer.minutes
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const time = localStorage.getItem("@countdown/remaining");
    if (time) {
      setTimer(time);
    }
  }, []);

  useEffect(() => {
    const saved =
      localStorage.getItem("@countdown/remaining") || Number.MAX_SAFE_INTEGER;
    const newValue = minutes + seconds / 60;
    if (saved > newValue) {
      localStorage.setItem("@countdown/remaining", newValue);
    }
  }, [seconds]);

  async function onSubmit(values) {
    if (Object.values(values).includes(null) && !isOpen && !isOver()) {
      onOpen();
    } else {
      const result = Object.values(values).reduce(
        (acc, i) => acc + (Number(i) || 0),
        0
      );

      await updateCurrentUser({
        reasoning: result,
        progress: 2,
        finishedReasoningTest: !(isOver() || isOpen),
      });
      await updateHubspotContact({
        reasoning_grade: result,
        finished_reasoning_test: !(isOver() || isOpen),
      });
      goToNext();
      window.scrollTo(0, 0);
    }
  }

  return (
    <Box
      px={{ base: 6, sm: 12 }}
      py={{ base: 8, sm: 16 }}
      w="100%"
      my={8}
      bg="white"
      rounded="md"
      shadow="md"
    >
      <Flex justify="space-between" mb={8}>
        <Heading size="md">{title}</Heading>
        <Flex border="2px" borderColor="gray.200" padding={2} borderRadius="xl">
          <HiClock size={28} />
          <Box ml="2">
            <Text
              fontSize="lg"
              fontWeight="bold"
            >{`${minutes}:${seconds}`}</Text>
          </Box>
        </Flex>
      </Flex>
      <Text>{introduction}</Text>

      {test && (
        <>
          <form id="reasoning" onSubmit={handleSubmit(onSubmit)}>
            <Stack mt={8} spacing={8}>
              {test.questions.map((question, index) => {
                return (
                  <FormControl key={index}>
                    <FormLabel htmlFor={question.name}>
                      {question.name}
                      {question.tip && (
                        <Popover>
                          <PopoverTrigger>
                            <IconButton
                              borderRadius="100"
                              ml={1}
                              variant="outline"
                              colorScheme="gray"
                              fontSize="18px"
                              size="xs"
                              borderColor="white"
                              icon={<HiOutlineInformationCircle color="gray" />}
                            />
                          </PopoverTrigger>
                          <PopoverContent
                            color="white"
                            bg="brand.600"
                            borderColor="brand.600"
                          >
                            <PopoverArrow bg="brand.600" />
                            <PopoverCloseButton />
                            <PopoverBody>{question.tip}</PopoverBody>
                          </PopoverContent>
                        </Popover>
                      )}
                    </FormLabel>

                    {question.imageUrl && <Image src={question.imageUrl} />}
                    <Text my={4}>{question.description}</Text>
                    <RadioGroup name={question.id}>
                      <Stack>
                        {question.answers.map((answer, index) => (
                          <Radio
                            {...register(question.id)}
                            key={index}
                            value={answer.value.toString()}
                          >
                            {answer.name}
                          </Radio>
                        ))}
                      </Stack>
                    </RadioGroup>
                  </FormControl>
                );
              })}
            </Stack>

            <HStack mt={8} justify="end">
              <Button
                size="lg"
                width={{ base: "100%", sm: "auto" }}
                isLoading={isSubmitting}
                type="submit"
              >
                Enviar
              </Button>
            </HStack>
          </form>
        </>
      )}
      <Modal isOpen={isOver()} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{countdownTimer.onFinishMessage}</ModalHeader>
          <ModalFooter>
            <Button mr={3} type="submit" form="reasoning">
              {countdownTimer.onFinishButtonText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{test.onSubmit.incompleteMessage}</ModalHeader>
          <ModalFooter>
            <Button onClick={onClose} variant="ghost">
              {test.onSubmit.backButtonText}
            </Button>
            <Button ml={3} type="submit" form="reasoning">
              {test.onSubmit.nextButtonText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
