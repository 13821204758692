import { extendTheme, withDefaultColorScheme } from "@chakra-ui/react";

const variantOutlined = () => ({
  field: {
    _focus: {
      borderColor: "#34b19d",
      boxShadow: "0 0 0 1px #34b19d",
    },
  },
});

export default extendTheme(
  {
    styles: {
      global: {
        body: {
          bg: "brand.50",
        },
      },
    },
    colors: {
      brand: {
        50: "#defcf8",
        100: "#bdf0e7",
        200: "#99e3d7",
        300: "#73d6c7",
        400: "#4ecbb7",
        500: "#34b19d",
        600: "#258a7a",
        700: "#166357",
        800: "#043c34",
        900: "#001711",
      },
    },
    components: {
      Input: {
        variants: {
          outline: variantOutlined,
        },
      },
      Select: {
        variants: {
          outline: variantOutlined,
        },
      },
      Textarea: {
        variants: {
          outline: () => variantOutlined().field,
        },
      },
    },
  },
  withDefaultColorScheme({ colorScheme: "brand" })
);
