import { Text, UnorderedList, ListItem } from "@chakra-ui/react";
import svg1 from "./assets/1.svg";
import svg2 from "./assets/2.svg";
import svg3 from "./assets/3.svg";

const config = {
  "bootcamp-pw": {
    name: "Bootcamp de programación web",
    steps: [
      {
        name: "Bienvenida",
        type: "welcome",
        props: {
          title: "¡Bienvenida a Adalab Academy!",
          introduction: (
            <>
              <Text mb={4}>
                Aquí podrás realizar todo el proceso de admisión de Adalab,
                compuesto por:
              </Text>
              <UnorderedList spacing={4}>
                <ListItem>
                  <b>Prueba de razonamiento</b> (20 minutos): Esta prueba no
                  mide conocimientos específicos y no es bloqueante en el
                  proceso. Sirve para conocer tu capacidad de razonar, analizar
                  información y resolver problemas, habilidades que te serán muy
                  útiles para afrontar el bootcamp. Tendrás 20 minutos para
                  completarla.
                </ListItem>
                <ListItem>
                  <b>Conversación personal</b> (30 minutos): Queremos conocerte
                  mejor y comprender tus motivaciones y trayectoria profesional
                  hasta ahora. Agendarás una reunión con un miembro del equipo
                  de Student Success y aprovecharemos ese espacio para resolver
                  todas tus dudas sobre la dinámica del bootcamp. La
                  conversación tendrá una duración de 30 minutos.
                </ListItem>
              </UnorderedList>

              <Text my={4}>
                ¡Enhorabuena por dar el paso! Clara es una adalaber que hizo el
                proceso admisión hace tiempo y tiene un mensaje especial para ti
                en este vídeo. ¡Dale al play!
              </Text>
            </>
          ),
          videoUrl: "https://www.youtube.com/embed/bxCjZwp4sLM",

          form: {
            introduction:
              "Antes de empezar, necesitamos saber un poco más sobre ti:",
            fields: [
              {
                name: "studiesLevel",
                hubspotId: "studies_level",
                label: "Estudios alcanzados",
                options: [
                  {
                    label: "Sin estudios obligatorios",
                    value: "Sin estudios obligatorios",
                  },
                  { label: "ESO", value: "ESO" },
                  { label: "Bachillerato", value: "Bachillerato" },
                  { label: "FP Básica", value: "FP Básica" },
                  { label: "FP Media", value: "FP Media" },
                  { label: "FP Superior", value: "FP Superior" },
                  {
                    label: "Estudios universitarios",
                    value: "Estudios universitarios",
                  },
                  { label: "Máster / Postgrado", value: "Máster / Postgrado" },
                ],
              },
              {
                name: "aboutYourStudies",
                hubspotId: "about_your_studies",
                label: "De qué son tus estudios",
              },
              {
                name: "englishLevel",
                hubspotId: "english_level",
                label: "Nivel de inglés",
                options: [
                  { label: "A1", value: "1" },
                  { label: "A2", value: "2" },
                  { label: "B1", value: "3" },
                  { label: "B2", value: "4" },
                  { label: "C1", value: "5" },
                  { label: "C2", value: "6" },
                ],
              },
              {
                name: "employmentStatus",
                hubspotId: "employment_status",
                label: "Situación laboral",
                options: [
                  {
                    label: "Desempleada sin prestación por desempleo",
                    value: "Desempleada sin prestación por desempleo",
                  },
                  {
                    label: "Desempleada con prestación por desempleo",
                    value: "Desempleada con prestación por desempleo",
                  },
                  {
                    label:
                      "Trabajo pero lo dejaré o me pediré una excedencia para entrar en Adalab",
                    value:
                      "Trabajo pero lo dejaré o me pediré una excedencia para entrar en Adalab",
                  },
                  {
                    label: "Trabajo y mi intención es seguir trabajando",
                    value: "Trabajo y no puedo dejarlo para entrar en Adalab",
                  },
                ],
              },
              {
                name: "validTimeZone",
                hubspotId: "valid_time_zone",
                label:
                  "¿Puedes asistir a las clases online en directo en el huso horario de Madrid (UTC+1)?",
                options: [
                  { label: "Sí puedo asistir a las clases", value: "Sí" },
                  { label: "No puedo asisitir a las clases", value: "No" },
                ],
              },
              {
                name: "whyAdalab",
                hubspotId: "why_adalab",
                label: "¿Por qué Adalab?",
                options: [
                  {
                    label:
                      "Vengo de otro sector y quiero reinventarme para trabajar en el sector tecnológico",
                    value:
                      "Vengo de otro sector y quiero reinventarme para trabajar como programadora",
                  },
                  {
                    label:
                      "Tengo un perfil tecnológico y quiero especializarme",
                    value:
                      "Tengo un perfil tecnológico y quiero enfocarme en programación",
                  },
                  {
                    label: "Quiero aprender y mejorar mi perfil profesional",
                    value: "Quiero aprender y mejorar mi perfil",
                  },
                  { label: "Quiero emprender", value: "Quiero empreender" },
                  { label: "Otro", value: "Otro" },
                ],
              },
              // {
              //   name: "bootcamp",
              //   hubspotId: "bootcamp",
              //   label: "Bootcamp",
              //   type: "hidden",
              //   value: "Programación web",
              // },
            ],
          },
        },
      },
      {
        name: "Prueba",
        type: "reasoning",
        props: {
          title: "Prueba de razonamiento",
          introduction: "Contesta a las siguientes preguntas:",
          countdownTimer: {
            minutes: 20,
            onFinishMessage:
              "¡Ups! Ha terminado el tiempo, tu prueba se enviará de forma automática.",
            onFinishButtonText: "Continuar",
          },
          test: {
            onSubmit: {
              incompleteMessage:
                "Parece que no has contestado a todas las preguntas. ¿Estás segura de que quieres terminar la prueba?",
              backButtonText: "Volver",
              nextButtonText: "Terminar",
            },
            questions: [
              {
                name: "1. ¿Cuál de los siguientes elementos es menos similar a los demás?",
                id: "question1",
                answers: [
                  { name: "A. cubo.", value: "a" },
                  { name: "B. esfera.", value: "b" },
                  { name: "C. pirámide.", value: "c" },
                  { name: "D. círculo.", value: "1" },
                ],
              },
              {
                name: "2. MEAT es a TEAM como 3521 es a...?",
                id: "question2",
                tip: 'Esta pregunta te pedirá que hagas una comparación. Las preguntas comparan cosas en el formato "A es a B como C es a ___", y tendrás que elegir qué opción encaja mejor en el espacio.',
                answers: [
                  { name: "A. 5312", value: "a" },
                  { name: "B. 1235", value: "b" },
                  { name: "C. 2135", value: "c" },
                  { name: "D. 1523", value: "1" },
                  { name: "E. 3521", value: "e" },
                ],
              },
              {
                name: "3. Considera un lenguaje que utiliza el siguiente conjunto de caracteres:",
                id: "question3",
                description: (
                  <>
                    <Text>Conjunto pequeño: &#123; a b c &#125;</Text>
                    <Text>Conjunto grande: &#123; A B C &#125;</Text>
                    <Text>Conjunto de puntuación: &#123; x y &#125;</Text>

                    <Text mt={4}>
                      Este lenguaje debe seguir las siguientes reglas:
                    </Text>
                    <UnorderedList mt={4}>
                      <ListItem>
                        Un carácter de puntuación debe terminar todas las
                        series.
                      </ListItem>
                      <ListItem>
                        Una serie puede tener hasta pero no más de 4 caracteres,
                        incluyendo los caracteres de puntuación.
                      </ListItem>
                    </UnorderedList>
                    <Text mt={4}>
                      ¿Sigue la serie <b>axBy</b> todas las reglas del lenguaje
                      definido arriba?
                    </Text>
                  </>
                ),
                tip: "En esta pregunta, te daremos un conjunto de caracteres de un lenguaje y una lista de reglas de sintaxis para el conjunto. También te daremos una serie de muestras correctas para el lenguaje. Estos caracteres del lenguaje y las reglas de sintaxis son completamente arbitrarios y no debes buscar una explicación racional, simplemente sigue las reglas tal como se dan. Verifica la serie de caracteres para asegurarte de que se hayan seguido todas las reglas de sintaxis. Si es así, marca la pregunta como correcta. Si no lo han hecho, marca la pregunta como incorrecta.",
                answers: [
                  { name: "A. Correcto", value: "1" },
                  { name: "B. Incorrecto", value: "b" },
                ],
              },
              {
                name: "4. Considera un lenguaje que utiliza el siguiente conjunto de caracteres:",
                id: "question4",
                description: (
                  <>
                    <Text>Conjunto pequeño: &#123; a b c ... z &#125;</Text>
                    <Text>Conjunto grande: &#123; A B C ... Z &#125;</Text>
                    <Text>
                      Conjunto de puntuación: &#123; 0 1 2 3 4 5 6 7 8 9 &#125;
                    </Text>

                    <Text mt={4}>
                      Este lenguaje debe seguir las siguientes reglas:
                    </Text>
                    <UnorderedList mt={4}>
                      <ListItem>
                        Una serie puede tener hasta, pero no más de 8
                        caracteres.
                      </ListItem>
                      <ListItem>
                        Una serie no puede comenzar con un carácter del conjunto
                        pequeño.
                      </ListItem>
                      <ListItem>
                        Una serie no puede terminar con un carácter del conjunto
                        grande.
                      </ListItem>
                      <ListItem>
                        Cuando una serie termina con un carácter del conjunto de
                        números, debe ser un número impar.
                      </ListItem>
                    </UnorderedList>
                    <Text mt={4}>Ejemplo de una serie correcta: Amber95</Text>
                    <Text mt={4}>
                      ¿Sigue la serie <b>2cat3</b> todas las reglas del lenguaje
                      definido arriba?
                    </Text>
                  </>
                ),
                tip: "En esta pregunta, te daremos un conjunto de caracteres de un lenguaje y una lista de reglas de sintaxis para el conjunto. También te daremos una serie de muestras correctas para el lenguaje. Estos caracteres del lenguaje y las reglas de sintaxis son completamente arbitrarios y no debes buscar una explicación racional, simplemente sigue las reglas tal como se dan. Verifica la serie de caracteres para asegurarte de que se hayan seguido todas las reglas de sintaxis. Si es así, marca la pregunta como correcta. Si no lo han hecho, marca la pregunta como incorrecta.",
                answers: [
                  { name: "A. Correcto", value: "1" },
                  { name: "B. Incorrecto", value: "b" },
                ],
              },
              {
                name: "5. Considera el siguiente diagrama de flujo para un cliente:",
                id: "question5",
                description: "La persona en el número 1 es:",
                imageUrl: svg1,
                tip: " En esta pregunta verás un diagrama de flujo que ilustra un procedimiento simple. Cada pregunta te pedirá que determines la secuencia lógica de pasos en el procedimiento.",
                answers: [
                  { name: "A. Casada, con hijos.", value: "a" },
                  { name: "B. Casada, con al menos un hijo.", value: "b" },
                  { name: "C. Soltera, con al menos una hija.", value: "c" },
                  { name: "D. Soltera, con al menos un hijo.", value: "1" },
                  { name: "E. Soltera, sin hijos.", value: "e" },
                ],
              },
              {
                name: "6. Considera el siguiente diagrama de flujo para un cliente de una tienda:",
                id: "question6",
                description: "¿Cual de las siguientes afirmaciones es cierta?",
                imageUrl: svg2,
                tip: " En esta pregunta verás un diagrama de flujo que ilustra un procedimiento simple. Cada pregunta te pedirá que determines la secuencia lógica de pasos en el procedimiento.",
                answers: [
                  {
                    name: "A. El cliente ha solicitado un cambio de un producto y se le ha dicho que no.",
                    value: "a",
                  },
                  {
                    name: "B. El cliente ha solicitado un cambio de producto y se ha buscado en el almacén.",
                    value: "b",
                  },
                  {
                    name: "C. El cliente ha solicitado un cambio, si está disponible el color solicitado en el almacén se le realiza el cambio.",
                    value: "1",
                  },
                  {
                    name: "D. El cliente ha solicitado un cambio de producto y se ha pedido a otra tienda.",
                    value: "d",
                  },
                ],
              },
              {
                name: "7. Considera el siguiente diagrama de flujo para  un grupo de animales que necesitas clasificar en listas de mamíferos, aves, peces, anfibios y reptiles.",
                id: "question7",
                description: "Un animal en A:",
                imageUrl: svg3,
                tip: "En esta pregunta verás un diagrama de flujo que ilustra un procedimiento simple. Cada pregunta te pedirá que determines la secuencia lógica de pasos en el procedimiento.",
                answers: [
                  {
                    name: "A. Podría ser cualquier tipo de animal.",
                    value: "1",
                  },
                  {
                    name: "B. No puede ser un pájaro.",
                    value: "b",
                  },
                  {
                    name: "C. Lo más probable es que no sea un mamífero.",
                    value: "c",
                  },
                  {
                    name: "D. Es muy probable que es un mamífero o un ave.",
                    value: "d",
                  },
                  {
                    name: "E. Es un mamífero o un ave.",
                    value: "e",
                  },
                ],
              },
              {
                name: "8. Laura puede redactar 10 páginas en 5 minutos. Almudena puede redactar 5 páginas en 10 minutos. Trabajando juntas, ¿cuántas páginas pueden redactar juntas en 30 minutos?",
                id: "question8",
                tip: "Esta pregunta te pide que uses la lógica para encontrar la respuesta a un problema. La mayoría de los problemas involucran matemáticas, pero ninguno de ellos es complejo. Lee la pregunta, analiza la situación y elige la mejor respuesta.",
                answers: [
                  { name: "A. 15", value: "a" },
                  { name: "B. 20", value: "b" },
                  { name: "C. 25", value: "c" },
                  { name: "D. 65", value: "d" },
                  { name: "E. 75", value: "1" },
                ],
              },
              {
                name: "9. Considera la siguiente serie: 3,4,6,9,13. ¿Qué número viene después?",
                id: "question9",
                tip: "Esta pregunta te pide que uses la lógica para encontrar la respuesta a un problema. La mayoría de los problemas involucran matemáticas, pero ninguno de ellos es complejo. Lee la pregunta, analiza la situación y elige la mejor respuesta.",
                answers: [
                  { name: "A. 15", value: "a" },
                  { name: "B. 16", value: "b" },
                  { name: "C. 17", value: "c" },
                  { name: "D. 18", value: "1" },
                  { name: "E. 20", value: "e" },
                ],
              },
              {
                name: "10. Hoy tienes visita en casa, generalmente haces estofado para alimentar a 4 personas, pero hoy se ha juntado toda la familia y son 16 personas. Para una ración de 4 personas se necesitan 9 zanahorias. ¿Cuántas zanahorias necesitas para alimentar a 16 personas?",
                id: "question10",
                tip: "Esta pregunta te pide que uses la lógica para encontrar la respuesta a un problema. La mayoría de los problemas involucran matemáticas, pero ninguno de ellos es complejo. Lee la pregunta, analiza la situación y elige la mejor respuesta.",
                answers: [
                  { name: "A. 81", value: "a" },
                  { name: "B. 40", value: "b" },
                  { name: "C. 36", value: "1" },
                  { name: "D. 18", value: "d" },
                  { name: "E. 45", value: "e" },
                ],
              },
            ],
          },
        },
      },
      {
        name: "Agenda cita",
        type: "interview",
        props: {
          description:
            "Es el momento de agendar tu cita de 30 minutos con un miembro del equipo de Student Success. Aprovecharemos ese espacio para conocerte mejor y resolver todas tus dudas sobre la dinámica del bootcamp.",
          meetingUrl:
            "https://meetings-eu1.hubspot.com/ines-raimundo/entrevista-personal-programacion",
        },
      },
    ],
  },
  "bootcamp-da": {
    name: "Bootcamp de data analytics",
    steps: [
      {
        name: "Bienvenida",
        type: "welcome",
        props: {
          title: "¡Bienvenida a Adalab Academy!",
          introduction: (
            <>
              <Text mb={4}>
                Aquí podrás realizar todo el proceso de admisión de Adalab,
                compuesto por:
              </Text>
              <UnorderedList spacing={4}>
                <ListItem>
                  <b>Prueba de razonamiento</b> (20 minutos): Esta prueba no
                  mide conocimientos específicos y no es bloqueante en el
                  proceso. Sirve para conocer tu capacidad de razonar, analizar
                  información y resolver problemas, habilidades que te serán muy
                  útiles para afrontar el bootcamp. Tendrás 20 minutos para
                  completarla.
                </ListItem>
                <ListItem>
                  <b>Conversación personal</b> (30 minutos): Queremos conocerte
                  mejor y comprender tus motivaciones y trayectoria profesional
                  hasta ahora. Agendarás una reunión con un miembro del equipo
                  de Student Success y aprovecharemos ese espacio para resolver
                  todas tus dudas sobre la dinámica del bootcamp. La
                  conversación tendrá una duración de 30 minutos.
                </ListItem>
              </UnorderedList>

              <Text my={4}>
                ¡Enhorabuena por dar el paso! Clara es una adalaber que hizo el
                proceso admisión hace tiempo y tiene un mensaje especial para ti
                en este vídeo. ¡Dale al play!
              </Text>
            </>
          ),
          videoUrl: "https://www.youtube.com/embed/bxCjZwp4sLM",
          form: {
            introduction:
              "Antes de empezar, necesitamos saber un poco más sobre ti:",
            fields: [
              {
                name: "studiesLevel",
                hubspotId: "studies_level",
                label: "Estudios alcanzados",
                options: [
                  {
                    label: "Sin estudios obligatorios",
                    value: "Sin estudios obligatorios",
                  },
                  { label: "ESO", value: "ESO" },
                  { label: "Bachillerato", value: "Bachillerato" },
                  { label: "FP Básica", value: "FP Básica" },
                  { label: "FP Media", value: "FP Media" },
                  { label: "FP Superior", value: "FP Superior" },
                  {
                    label: "Estudios universitarios",
                    value: "Estudios universitarios",
                  },
                  { label: "Máster / Postgrado", value: "Máster / Postgrado" },
                ],
              },
              {
                name: "aboutYourStudies",
                hubspotId: "about_your_studies",
                label: "De qué son tus estudios",
              },
              {
                name: "englishLevel",
                hubspotId: "english_level",
                label: "Nivel de inglés",
                options: [
                  { label: "A1", value: "1" },
                  { label: "A2", value: "2" },
                  { label: "B1", value: "3" },
                  { label: "B2", value: "4" },
                  { label: "C1", value: "5" },
                  { label: "C2", value: "6" },
                ],
              },
              {
                name: "employmentStatus",
                hubspotId: "employment_status",
                label: "Situación laboral",
                options: [
                  {
                    label: "Desempleada sin prestación por desempleo",
                    value: "Desempleada sin prestación por desempleo",
                  },
                  {
                    label: "Desempleada con prestación por desempleo",
                    value: "Desempleada con prestación por desempleo",
                  },
                  {
                    label:
                      "Trabajo pero lo dejaré o me pediré una excedencia para entrar en Adalab",
                    value:
                      "Trabajo pero lo dejaré o me pediré una excedencia para entrar en Adalab",
                  },
                  {
                    label: "Trabajo y mi intención es seguir trabajando",
                    value: "Trabajo y no puedo dejarlo para entrar en Adalab",
                  },
                ],
              },
              {
                name: "validTimeZone",
                hubspotId: "valid_time_zone",
                label:
                  "¿Puedes asistir a las clases online en directo en el huso horario de Madrid (UTC+1)?",
                options: [
                  { label: "Sí puedo asistir a las clases", value: "Sí" },
                  { label: "No puedo asisitir a las clases", value: "No" },
                ],
              },
              {
                name: "whyAdalab",
                hubspotId: "why_adalab",
                label: "¿Por qué Adalab?",
                options: [
                  {
                    label:
                      "Vengo de otro sector y quiero reinventarme para trabajar en el sector tecnológico",
                    value:
                      "Vengo de otro sector y quiero reinventarme para trabajar como programadora",
                  },
                  {
                    label:
                      "Tengo un perfil tecnológico y quiero especializarme",
                    value:
                      "Tengo un perfil tecnológico y quiero enfocarme en programación",
                  },
                  {
                    label: "Quiero aprender y mejorar mi perfil profesional",
                    value: "Quiero aprender y mejorar mi perfil",
                  },
                  { label: "Quiero emprender", value: "Quiero empreender" },
                  { label: "Otro", value: "Otro" },
                ],
              },
            ],
          },
        },
      },
      {
        name: "Prueba",
        type: "reasoning",
        props: {
          title: "Prueba de razonamiento",
          introduction: "Contesta a las siguientes preguntas:",
          countdownTimer: {
            minutes: 20,
            onFinishMessage:
              "¡Ups! Ha terminado el tiempo, tu prueba se enviará de forma automática.",
            onFinishButtonText: "Continuar",
          },
          test: {
            onSubmit: {
              incompleteMessage:
                "Parece que no has contestado a todas las preguntas. ¿Estás segura de que quieres terminar la prueba?",
              backButtonText: "Volver",
              nextButtonText: "Terminar",
            },
            questions: [
              {
                name: "1. ¿Cuál de los siguientes elementos es menos similar a los demás?",
                id: "question1",
                answers: [
                  { name: "A. cubo.", value: "a" },
                  { name: "B. esfera.", value: "b" },
                  { name: "C. pirámide.", value: "c" },
                  { name: "D. círculo.", value: "1" },
                ],
              },
              {
                name: "2. MEAT es a TEAM como 3521 es a...?",
                id: "question2",
                tip: 'Esta pregunta te pedirá que hagas una comparación. Las preguntas comparan cosas en el formato "A es a B como C es a ___", y tendrás que elegir qué opción encaja mejor en el espacio.',
                answers: [
                  { name: "A. 5312", value: "a" },
                  { name: "B. 1235", value: "b" },
                  { name: "C. 2135", value: "c" },
                  { name: "D. 1523", value: "1" },
                  { name: "E. 3521", value: "e" },
                ],
              },
              {
                name: "3. Considera un lenguaje que utiliza el siguiente conjunto de caracteres:",
                id: "question3",
                description: (
                  <>
                    <Text>Conjunto pequeño: &#123; a b c &#125;</Text>
                    <Text>Conjunto grande: &#123; A B C &#125;</Text>
                    <Text>Conjunto de puntuación: &#123; x y &#125;</Text>

                    <Text mt={4}>
                      Este lenguaje debe seguir las siguientes reglas:
                    </Text>
                    <UnorderedList mt={4}>
                      <ListItem>
                        Un carácter de puntuación debe terminar todas las
                        series.
                      </ListItem>
                      <ListItem>
                        Una serie puede tener hasta pero no más de 4 caracteres,
                        incluyendo los caracteres de puntuación.
                      </ListItem>
                    </UnorderedList>
                    <Text mt={4}>
                      ¿Sigue la serie <b>axBy</b> todas las reglas del lenguaje
                      definido arriba?
                    </Text>
                  </>
                ),
                tip: "En esta pregunta, te daremos un conjunto de caracteres de un lenguaje y una lista de reglas de sintaxis para el conjunto. También te daremos una serie de muestras correctas para el lenguaje. Estos caracteres del lenguaje y las reglas de sintaxis son completamente arbitrarios y no debes buscar una explicación racional, simplemente sigue las reglas tal como se dan. Verifica la serie de caracteres para asegurarte de que se hayan seguido todas las reglas de sintaxis. Si es así, marca la pregunta como correcta. Si no lo han hecho, marca la pregunta como incorrecta.",
                answers: [
                  { name: "A. Correcto", value: "1" },
                  { name: "B. Incorrecto", value: "b" },
                ],
              },
              {
                name: "4. Considera un lenguaje que utiliza el siguiente conjunto de caracteres:",
                id: "question4",
                description: (
                  <>
                    <Text>Conjunto pequeño: &#123; a b c ... z &#125;</Text>
                    <Text>Conjunto grande: &#123; A B C ... Z &#125;</Text>
                    <Text>
                      Conjunto de puntuación: &#123; 0 1 2 3 4 5 6 7 8 9 &#125;
                    </Text>

                    <Text mt={4}>
                      Este lenguaje debe seguir las siguientes reglas:
                    </Text>
                    <UnorderedList mt={4}>
                      <ListItem>
                        Una serie puede tener hasta, pero no más de 8
                        caracteres.
                      </ListItem>
                      <ListItem>
                        Una serie no puede comenzar con un carácter del conjunto
                        pequeño.
                      </ListItem>
                      <ListItem>
                        Una serie no puede terminar con un carácter del conjunto
                        grande.
                      </ListItem>
                      <ListItem>
                        Cuando una serie termina con un carácter del conjunto de
                        números, debe ser un número impar.
                      </ListItem>
                    </UnorderedList>
                    <Text mt={4}>Ejemplo de una serie correcta: Amber95</Text>
                    <Text mt={4}>
                      ¿Sigue la serie <b>2cat3</b> todas las reglas del lenguaje
                      definido arriba?
                    </Text>
                  </>
                ),
                tip: "En esta pregunta, te daremos un conjunto de caracteres de un lenguaje y una lista de reglas de sintaxis para el conjunto. También te daremos una serie de muestras correctas para el lenguaje. Estos caracteres del lenguaje y las reglas de sintaxis son completamente arbitrarios y no debes buscar una explicación racional, simplemente sigue las reglas tal como se dan. Verifica la serie de caracteres para asegurarte de que se hayan seguido todas las reglas de sintaxis. Si es así, marca la pregunta como correcta. Si no lo han hecho, marca la pregunta como incorrecta.",
                answers: [
                  { name: "A. Correcto", value: "1" },
                  { name: "B. Incorrecto", value: "b" },
                ],
              },
              {
                name: "5. Considera el siguiente diagrama de flujo para un cliente:",
                id: "question5",
                description: "La persona en el número 1 es:",
                imageUrl: svg1,
                tip: " En esta pregunta verás un diagrama de flujo que ilustra un procedimiento simple. Cada pregunta te pedirá que determines la secuencia lógica de pasos en el procedimiento.",
                answers: [
                  { name: "A. Casada, con hijos.", value: "a" },
                  { name: "B. Casada, con al menos un hijo.", value: "b" },
                  { name: "C. Soltera, con al menos una hija.", value: "c" },
                  { name: "D. Soltera, con al menos un hijo.", value: "1" },
                  { name: "E. Soltera, sin hijos.", value: "e" },
                ],
              },
              {
                name: "6. Considera el siguiente diagrama de flujo para un cliente de una tienda:",
                id: "question6",
                description: "¿Cual de las siguientes afirmaciones es cierta?",
                imageUrl: svg2,
                tip: " En esta pregunta verás un diagrama de flujo que ilustra un procedimiento simple. Cada pregunta te pedirá que determines la secuencia lógica de pasos en el procedimiento.",
                answers: [
                  {
                    name: "A. El cliente ha solicitado un cambio de un producto y se le ha dicho que no.",
                    value: "a",
                  },
                  {
                    name: "B. El cliente ha solicitado un cambio de producto y se ha buscado en el almacén.",
                    value: "b",
                  },
                  {
                    name: "C. El cliente ha solicitado un cambio, si está disponible el color solicitado en el almacén se le realiza el cambio.",
                    value: "1",
                  },
                  {
                    name: "D. El cliente ha solicitado un cambio de producto y se ha pedido a otra tienda.",
                    value: "d",
                  },
                ],
              },
              {
                name: "7. Considera el siguiente diagrama de flujo para  un grupo de animales que necesitas clasificar en listas de mamíferos, aves, peces, anfibios y reptiles.",
                id: "question7",
                description: "Un animal en A:",
                imageUrl: svg3,
                tip: "En esta pregunta verás un diagrama de flujo que ilustra un procedimiento simple. Cada pregunta te pedirá que determines la secuencia lógica de pasos en el procedimiento.",
                answers: [
                  {
                    name: "A. Podría ser cualquier tipo de animal.",
                    value: "1",
                  },
                  {
                    name: "B. No puede ser un pájaro.",
                    value: "b",
                  },
                  {
                    name: "C. Lo más probable es que no sea un mamífero.",
                    value: "c",
                  },
                  {
                    name: "D. Es muy probable que es un mamífero o un ave.",
                    value: "d",
                  },
                  {
                    name: "E. Es un mamífero o un ave.",
                    value: "e",
                  },
                ],
              },
              {
                name: "8. Laura puede redactar 10 páginas en 5 minutos. Almudena puede redactar 5 páginas en 10 minutos. Trabajando juntas, ¿cuántas páginas pueden redactar juntas en 30 minutos?",
                id: "question8",
                tip: "Esta pregunta te pide que uses la lógica para encontrar la respuesta a un problema. La mayoría de los problemas involucran matemáticas, pero ninguno de ellos es complejo. Lee la pregunta, analiza la situación y elige la mejor respuesta.",
                answers: [
                  { name: "A. 15", value: "a" },
                  { name: "B. 20", value: "b" },
                  { name: "C. 25", value: "c" },
                  { name: "D. 65", value: "d" },
                  { name: "E. 75", value: "1" },
                ],
              },
              {
                name: "9. Considera la siguiente serie: 3,4,6,9,13. ¿Qué número viene después?",
                id: "question9",
                tip: "Esta pregunta te pide que uses la lógica para encontrar la respuesta a un problema. La mayoría de los problemas involucran matemáticas, pero ninguno de ellos es complejo. Lee la pregunta, analiza la situación y elige la mejor respuesta.",
                answers: [
                  { name: "A. 15", value: "a" },
                  { name: "B. 16", value: "b" },
                  { name: "C. 17", value: "c" },
                  { name: "D. 18", value: "1" },
                  { name: "E. 20", value: "e" },
                ],
              },
              {
                name: "10. Hoy tienes visita en casa, generalmente haces estofado para alimentar a 4 personas, pero hoy se ha juntado toda la familia y son 16 personas. Para una ración de 4 personas se necesitan 9 zanahorias. ¿Cuántas zanahorias necesitas para alimentar a 16 personas?",
                id: "question10",
                tip: "Esta pregunta te pide que uses la lógica para encontrar la respuesta a un problema. La mayoría de los problemas involucran matemáticas, pero ninguno de ellos es complejo. Lee la pregunta, analiza la situación y elige la mejor respuesta.",
                answers: [
                  { name: "A. 81", value: "a" },
                  { name: "B. 40", value: "b" },
                  { name: "C. 36", value: "1" },
                  { name: "D. 18", value: "d" },
                  { name: "E. 45", value: "e" },
                ],
              },
            ],
          },
        },
      },
      {
        name: "Agenda cita",
        type: "interview",
        props: {
          description:
            "Es el momento de agendar tu cita de 30 minutos con un miembro del equipo de Student Success. Aprovecharemos ese espacio para conocerte mejor y resolver todas tus dudas sobre la dinámica del bootcamp.",
          meetingUrl:
            "https://meetings-eu1.hubspot.com/ines-raimundo/entrevista-personal-data-analytics",
        },
      },
    ],
  },
};

export default config;

// https://admisiones.adalab.es/admission/bootcamp-pw?redirect=true
