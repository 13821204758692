import { useParams } from "react-router-dom";
import { Steps } from "../components/AdmisionProcess/StepManager";
import { Screen } from "../components/Screen";
import { useAdmissionProcess } from "../hooks/useAdmissionProcess";

export default function AdmissionProcessScreen() {
  let { bootcampId } = useParams();
  let { steps } = useAdmissionProcess(bootcampId);

  return (
    <Screen>
      <Steps steps={steps} />
    </Screen>
  );
}
